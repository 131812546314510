import React from 'react'
import './Tile.css'

var currNum = 1;

export const Tile = (props) => {

    const next = (id) => (event) => {
        var btn = document.getElementById(id);
        if (btn.classList.contains('possible')) {
            btn.innerHTML = currNum++;
            btn.classList.toggle('possible');
            btn.classList.toggle('taken');

            const regex = /\w+-(\d+)-(\d+)/;
            const match = btn.id.match(regex);
            const pos = [parseInt(match[1], 10), parseInt(match[2], 10)];
            const MAX = 5;
            var moves = [0, 3, 3, 0, 0, -3, -3, 0, -2, 2, 2, 2, 2, -2, -2, -2];

            for (var i = 0; i < 5; i++) {
                for (var j = 0; j < 5; j++) {
                    let c = document.getElementById(`tile-${i}-${j}`);
                    if (c.classList.contains('possible')) {
                        c.classList.toggle('possible');
                        c.classList.toggle('free');
                        c.innerHTML = "&nbsp;";
                    }
                }
            }

            var count = 0;

            for (var k = 0; k < 16;) {
                var x = pos[0] + moves[k++];
                var y = pos[1] + moves[k++];
                console.log(x, y);
                if (x >= 0 && x < MAX && y >= 0 && y < MAX) {
                    let c = document.getElementById(`tile-${x}-${y}`);
                    if (c.classList.contains('free')) {
                        c.classList.toggle('free');
                        c.classList.toggle('possible');
                        c.innerHTML = currNum;
                        count++;
                    }
                }
            }

            if (currNum === 26) {
                document.getElementById('bottom-txt').innerHTML = 'Nice!';
            }

            if (currNum !== 26 && count === 0) {
                for (let i = 0; i < 5; i++) {
                    for (let j = 0; j < 5; j++) {
                        let c = document.getElementById(`tile-${i}-${j}`);
                        if (c.classList.contains('taken')) c.classList.toggle('done');
                    }
                }
                document.getElementById('bottom-txt').innerHTML = 'That was bad.';
            }

        }
    }

    return (
        <button className={'tile ' + props.status} id={props.id} onClick={next(props.id)}>
            {props.value}
        </button>
    )
}
