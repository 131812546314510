import { Link } from 'react-router-dom';
import './App.css';
import { Title } from './components/Title';

function App() {
  return (
    <>
      <Title />
      <div className='menu gmc'>
        <Link to='/game'>5x5</Link>
      </div>
    </>
  );
}

export default App;
