import React from 'react'
import { Title } from '../Title'
import { Grid } from './Grid'
import { BackButton } from '../BackButton'
import './Game.css'
import { RetryButton } from '../RetryButton'

export const Game = (props) => {
    return (
        <>
            <Title />
            <div className='buttons'>
                <BackButton />
                <RetryButton />
            </div>
            <div id='grid' className='gmc'>
                <Grid size={props.size} />
            </div>
            <h2 id='bottom-txt'>&nbsp;</h2>
        </>
    )
}
