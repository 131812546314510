import React from 'react'
import { Tile } from './Tile'
import './Grid.css'

const Row = (props) => {
    return (
        [...Array(parseInt(props.size))].map((e, i) => <Tile id={`tile-${props.id}-${i}`} status='possible' value='1' />)
    )
}

export const Grid = (props) => {
    var pos = 0;
    return (
        [...Array(parseInt(props.size))].map((e, i) => {
            return <div className='row'><Row size={props.size} id={pos++} /></div>
        })
    )
}
