import React from 'react'
import { useNavigate } from 'react-router-dom'
import './Buttons.css'

export const BackButton = () => {
    const navigate = useNavigate();

    const goBack = () => navigate('..', { replace: true });

    return (
        <button className='button' onClick={goBack}><i class="fas fa-arrow-left"></i></button>
    )
}
